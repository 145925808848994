<template>
  <div class="business-wrapper">
    <header :class="['header']">
      <div class="logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="nav-cont">
        <div class="nav" @click="jumpTo('Home')">首 页</div>
        <!-- <div class="nav navActive">主要业务</div> -->
        <div class="nav" @click="jumpTo('About')">关于我们</div>
        <div class="nav" @click="jumpTo('Join')">加入我们</div>
      </div>
    </header>

    <div class="business">
      <div class="b-title">主要业务</div>
      <div class="b-title2">
        华越致力于为客户提供领先，安全，以扩展的信息化定制解决方案以及相关软件产品、平台服务
      </div>
      <div class="business-cont">
        <div class="card" @click="handleCard('development')">
          <div class="cont">
            <img src="../../assets/development.png" />
          </div>
          <div class="info">
            <div class="tit">研发体系咨询</div>
            <div class="text">
              华越的专家顾问组，可以为制造企业提供专业的咨询服务。
            </div>
          </div>
        </div>
        <div class="card" @click="handleCard('plm')">
          <div class="cont">
            <img src="../../assets/plm.png" />
          </div>
          <div class="info">
            <div class="tit">PLM规划与实施</div>
            <div class="text">
              主要是面向制造业的产品研发领域，支持企业内部研发数据的创建、管理、分发和应用的一系列应用解决方案。
            </div>
          </div>
        </div>
        <div class="card" @click="handleCard('center')">
          <div class="cont">
            <img src="../../assets/rd.png" />
          </div>
          <div class="info">
            <div class="tit">物联网</div>
            <div class="text">
              把所有物品通过网络连接起来，实现任何物体、任何人、任何时间、任何地点（4A）的智能化识别、信息交换与管理。
            </div>
          </div>
        </div>
        <!-- <div class="card" @click="handleCard('bi')">
          <div class="cont">
            <img src="../../assets/bi.png"/>
          </div>
          <div class="info">
            <div class="tit">BI规划与实施</div>
            <div class="text">商业智能是一种解决方案,为管理者的决策过程提供支持。</div>
          </div>
        </div>
        <div class="card" @click="handleCard('iot')">
          <div class="cont">
            <img src="../../assets/iot.png"/>
          </div>
          <div class="info">
            <div class="tit">物联网</div>
            <div class="text">各种信息传感设备与网络结合起来而形成的一个巨大网络，实现人、机、物的互联互通。</div>
          </div>
        </div>
        <div class="card" @click="handleCard('bigData')">
          <div class="cont">
            <img src="../../assets/bigData.png"/>
          </div>
          <div class="info">
            <div class="tit">大数据</div>
            <div class="text">自助BI报表分析和制作可视化数据大屏的强大工具，组件丰富，开箱即用，无需SQL和任何编码。</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="tabs">
      <div
        :class="['card', carrentId === 1 ? 'card-active' : '']"
        @mouseover="carrentId = 1"
      >
        <div class="info">
          <div></div>
          <div class="title1">
            科技大学项目 <span class="title2">案例 01/03</span>
          </div>
          <div class="line"></div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
            <p>
              IPD体系强调以市场为导向，将产品开发视为投资来进行管理。其核心思想概括如下:新产品开发是一项投资决策;基于市场的开发;跨部门、跨系统的协同;异步开发模式，也称并行工程;重用性（CBB)结构化的流程。从实施IPD体系的企业收益统计来看，主要的体现有（以下数据来自PRTM的统计分析）：
            </p>
          </div>
          <div class="title3">项目成功</div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
          </div>
        </div>
      </div>
      <div
        :class="['card', carrentId === 2 ? 'card-active' : '']"
        @mouseover="carrentId = 2"
      >
        <div class="info">
          <div class="title1">
            科技大学项目 <span class="title2">案例 02/03</span>
          </div>
          <div class="line"></div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
            <p>
              IPD体系强调以市场为导向，将产品开发视为投资来进行管理。其核心思想概括如下:新产品开发是一项投资决策;基于市场的开发;跨部门、跨系统的协同;异步开发模式，也称并行工程;重用性（CBB)结构化的流程。从实施IPD体系的企业收益统计来看，主要的体现有（以下数据来自PRTM的统计分析）：
            </p>
          </div>
          <div class="title3">项目成功</div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
          </div>
        </div>
      </div>
      <div
        :class="['card', carrentId === 3 ? 'card-active' : '']"
        @mouseover="carrentId = 3"
      >
        <div class="info">
          <div class="title1">
            科技大学项目 <span class="title2">案例 03/03</span>
          </div>
          <div class="line"></div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
            <p>
              IPD体系强调以市场为导向，将产品开发视为投资来进行管理。其核心思想概括如下:新产品开发是一项投资决策;基于市场的开发;跨部门、跨系统的协同;异步开发模式，也称并行工程;重用性（CBB)结构化的流程。从实施IPD体系的企业收益统计来看，主要的体现有（以下数据来自PRTM的统计分析）：
            </p>
          </div>
          <div class="title3">项目成功</div>
          <div class="text">
            <p>
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-cont">
        <div class="box1">
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M14,3H2C1.45,3,1,3.45,1,4v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V4C15,3.45,14.55,3,14,3z M14,12H2V4
                h12V12z"
                />
                <path
                  fill="#B8BECC"
                  d="M6.41,8.5h6c0.28,0,0.5-0.22,0.5-0.5s-0.22-0.5-0.5-0.5h-6c-0.28,0-0.5,0.22-0.5,0.5S6.13,8.5,6.41,8.5z"
                />
                <path
                  fill="#B8BECC"
                  d="M12.48,9.79h-6c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h6c0.28,0,0.5-0.22,0.5-0.5
                S12.75,9.79,12.48,9.79z"
                />
                <circle fill="#B8BECC" cx="4.33" cy="6.34" r="1" />
              </g>
            </svg>
            西安华越信息技术有限公司
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M8,1.5c-2.99,0-5.43,2.43-5.43,5.43c0,1.97,2.18,4.86,4,6.93C6.93,14.26,7.45,14.5,8,14.5
                  s1.07-0.24,1.43-0.64c1.83-2.07,4-4.96,4-6.93C13.43,3.93,10.99,1.5,8,1.5z M8.68,13.2c-0.35,0.39-1,0.39-1.35,0
                  C4.94,10.49,3.57,8.2,3.57,6.93C3.57,4.49,5.56,2.5,8,2.5s4.43,1.99,4.43,4.43C12.43,8.2,11.06,10.49,8.68,13.2z"
                />
                <path
                  fill="#B8BECC"
                  d="M8,4.5C6.62,4.5,5.5,5.62,5.5,7S6.62,9.5,8,9.5s2.5-1.12,2.5-2.5S9.38,4.5,8,4.5z M8,8.5
                  C7.17,8.5,6.5,7.83,6.5,7S7.17,5.5,8,5.5S9.5,6.17,9.5,7S8.83,8.5,8,8.5z"
                />
              </g>
            </svg>
            西安市高新高新区唐延南路与锦业路交汇处逸翠园i都会4号楼1单元2504
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13,9.56c-1.25-1.19-2.4-1.43-3.5-0.75C9.44,8.85,9.37,8.9,9.29,8.96L9.05,9.14C9.03,9.13,9,9.11,8.98,9.1
              c-0.43-0.33-0.82-0.7-1.16-1.12c-0.3-0.38-0.54-0.8-0.68-1.2L7.13,6.73l0.02-0.01c1.57-1.37,0.86-2.86,0.2-3.85
              C6.77,2,6.07,1.54,5.27,1.5h0c-0.82-0.04-1.41,0.32-2.1,0.9l-0.3,0.25C2.38,3.04,2.08,3.64,2.01,4.38C1.85,6.1,2.9,8.59,4.7,10.72
              c1.79,2.12,4.26,3.78,6.14,3.78c0.57,0,1.09-0.15,1.51-0.5l0.5-0.39c0.6-0.49,0.91-0.91,1.06-1.45C14.1,11.55,14.09,10.59,13,9.56z
              M12.96,11.89c-0.1,0.33-0.3,0.6-0.73,0.95l-0.5,0.39c-1.14,0.93-4.07-0.55-6.27-3.15c-1.61-1.9-2.59-4.16-2.45-5.6
              C3.05,4,3.22,3.65,3.5,3.42l0.31-0.26C4.45,2.62,4.79,2.5,5.15,2.5c0.02,0,0.05,0,0.07,0c0.48,0.02,0.9,0.32,1.3,0.92
              c0.89,1.33,0.67,1.94-0.01,2.53L6.04,6.36l0.04,0.28c0.02,0.11,0.04,0.22,0.08,0.34L6.2,7.09C6.37,7.6,6.68,8.14,7.05,8.61
              c0.39,0.48,0.84,0.91,1.32,1.28c0.1,0.08,0.21,0.15,0.34,0.24l0.37,0.22l0.81-0.59c0.05-0.04,0.1-0.07,0.15-0.1
              c0.51-0.32,1.14-0.46,2.28,0.63C12.9,10.84,13.11,11.36,12.96,11.89z"
              />
            </svg>
            电话：029-81149376
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13.5,3h-11c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V4C14.5,3.45,14.05,3,13.5,3z
              M12.72,4l-4.4,4.07C8.14,8.23,7.87,8.23,7.69,8.09L3.28,4H12.72z M2.5,12V4.64l4.53,4.19c0.28,0.24,0.62,0.35,0.96,0.35
              c0.35,0,0.7-0.12,0.99-0.37l4.51-4.18V12H2.5z"
              />
            </svg>
            hr@xa-huayue.com
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="footer-cont">
        <div class="box2">
          Copyright ©2014 - 2021 西安华越信息技术有限公司
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >陕ICP备14005811号</a
          >
        </div>
      </div>
    </div>
    <el-drawer
      class="drawer-class"
      :visible.sync="drawer"
      direction="btt"
      :before-close="handleClose"
    >
      <div class="drawer-cont">
        <div class="drawer-left">
          <div v-if="carrentBusiness === 'development'">
            <div class="title1">IPD研发体系</div>
            <div class="p1">
              IPD研发体系是目前具有代表性的产品与技术创新管理体系。先后经过IBM、华为等公司实践和完善，已成为业界最为领先的企业产品创新管理体系之一。
              <br />IPD体系强调以市场为导向，将产品开发视为投资来进行管理。其核心思想概括如下:新产品开发是一项投资决策;基于市场的开发;跨部门、跨系统的协同;异步开发模式，也称并行工程;重用性（CBB）结构化的流程。从实施IPD体系的企业收益统计来看，主要的体现有（以下数据来自PRTM的统计分析）：
            </div>
            <el-row class="chart-percent">
              <el-col :span="6" style="color: #6c92fa">40%-60%</el-col>
              <el-col :span="6" style="color: #64d2fd">50%-80%</el-col>
              <el-col :span="6" style="color: #44d7b6">25%-30%</el-col>
              <el-col :span="6" style="color: #79ed33">100%</el-col>
            </el-row>
            <el-row class="chart-info">
              <el-col :span="6">投入市场时间缩短</el-col>
              <el-col :span="6">开发浪费减少</el-col>
              <el-col :span="6">开发浪费减少</el-col>
              <el-col :span="6">新产品收益增加</el-col>
            </el-row>
            <div class="title1">华越研发体系咨询</div>
            <div class="p1">
              华越研发体系咨询，主要来源于IPD体系。目前咨询专家顾问团队，长期为华为、VIVO、联想等优秀企业的IPD项目服务，积累了丰富的行业实践经验。拥有多年的IPD体系构建、执行、实施、评估等咨询经验。可以为制造企业提供专业的咨询服务，结合企业能力与业务特性，量身打造。通过规划完善实现线路，运用先进管理体系，优化企业研发管理水平，大大提升企业创新研发管理能力。
            </div>
          </div>
          <div v-if="carrentBusiness === 'plm'">
            <div class="title1">PLM规划与实施</div>
            <div class="p1">
              主要是面向制造业的产品研发领域，支持企业内部研发数据的创建、管理、分发和应用的一系列应用解决方案，它能够集成与产品相关的人力资源、流程、应用系统和信息。
            </div>
            <div class="title1">易用性改造</div>
            <div class="p1">
              在为部分领先的制造企业服务的过程中，华越具备了良好的前后端规划实施能力，可以基于传统的PLM，采用前后端分离技术，从功能性和易用性都为用户带来更好的提升。
            </div>
          </div>
          <div v-if="carrentBusiness === 'center'">
            <div class="title1">中台规划</div>
            <div class="p1">
              在大数据时代，企业可以通过将产品技术力量和数据运营能力从前台剥离，成为能够独立为前台提供服务的中台，对前台业务形成强力支撑，从而使前台业务更加精简、敏捷，速适应瞬息万变的市场。<br />
              中台作为一种产品设计思路，或者系统架构思路，并不受限于公司的规模，理论上讲，任何一家即将或者正在面临业务高速增长的状态时，都很值得利用和借鉴中台的思路，将目前业务当中大量可复用的功能和场景进行梳理，为业务的高速增长做好准备。
            </div>
            <div class="title1">华越研发体系咨询</div>
            <div class="p1">
              华越拥有具有丰富中台经验的业务团队和技术团队，长期服务于国内一线企业，可以为企业中台化战略提供良好的规划与实施服务。
            </div>
          </div>
          <div v-if="carrentBusiness === 'bi'">
            <div class="title1"></div>
            <div class="p1">
              商业智能是一种解决方案，是数据仓库、在线分析处理（OLAP）、数据挖掘、数据展现等技术的综合运用。商业智能的关键是从许多来自不同的企业运作系统的数据中提取出有用的数据并进行清理，以保证数据的正确性，然后经过抽取（Extraction）、转换（Transformation）和装载（Load），即ETL过程，合并到一个企业级的数据仓库里，从而得到企业数据的一个全局视图，在此基础上利用合适的查询和分析工具、数据挖掘工具、OLAP工具等对其进行分析和处理，最后将知识呈现给管理者，为管理者的决策过程提供支持。
            </div>
            <div class="p1">
              IPD体系强调以市场为导向，将产品开发视为投资来进行管理。其核心思想概括如下:新产品开发是一项投资决策;基于市场的开发;跨部门、跨系统的协同;异步开发模式，也称并行工程;重用性（CBB）结构化的流程。
            </div>
            <div class="title1">实施能力</div>
            <div class="p1">
              华越的BI团队目前正服务于国内领先的制造企业，具有市场前沿的理论和实践经验，可以为企业的BI建设提供可信赖的实施服务。
            </div>
          </div>
          <div v-if="carrentBusiness === 'iot'">
            <div class="title1">项目背景</div>
            <div class="p1">
              综合能源监管一体化解决方案是以智能设备为基础，以通讯技术为桥梁，以软件平台为中央大脑，对用能主体使用过程中的水、电、气、供热、供冷、油等能耗数据以及室内外环境信息数据进行动态监测，长效管理，数智管控。
            </div>
            <div class="p1">
              通过能耗数据自动采集、对能耗数据分时、分类、分项统计加工，组合多维数据进行能耗评估、智能决策来挖掘节能潜力，保障用能舒适度，优化节能措施，提升用能安全，不断从设备节能，管理节能，运营节能三个方向推进节能减排工作的高效开展。
            </div>
            <div class="title1">平台介绍</div>
            <div class="p1">
              物联网平台是基于现有最新技术,将硬件设备接入到云端，硬件设备定时上报采集数据，云端对上报的数据进行过滤清洗分析之后展示硬件设备的运行状态，能耗信息，环境信息等，当设备出现告警时，下发对应的指令，进行设备实时控制，最后基于大量的设备数据进行大数据分析后，给出运行报表，节能报表。
            </div>
          </div>
          <div v-if="carrentBusiness === 'bigData'">
            <div class="title1">大数据</div>
            <div class="p1">
              大数据是指无法在一定时间内用常规软件工具对其内容进行抓取、管理和处理的数据集合。大数据技术，是指从各种各样类型的数据中，快速获得有价值信息的能力。适用于大数据的技术，包括大规模并行处理（MPP）数据库，数据挖掘电网，分布式文件系统，分布式数据库，云计算平台，互联网，和可扩展的存储系统。
            </div>
            <div class="title1">大数据的特点</div>
            <div class="p1">
              <div class="title2">数据体量巨大</div>
              百度资料表明，其新首页导航每天需要提供的数据超过1.5PB（1PB=1024TB），这些数据如果打印出来将超过5千亿张A4纸。有资料证实，到目前为止，人类生产的所有印刷材料的
            </div>
            <div class="p1">
              <div class="title2">数据类型多样</div>
              现在的数据类型不仅是文本形式，更多的是图片、视频、音频、地理位置信息等多类型的数据，个性化数据占绝对多数。
            </div>
            <div class="p1">
              <div class="title2">处理速度快</div>
              数据处理遵循“1秒定律”，可从各种类型的数据中快速获得高价值的信息。
            </div>
            <div class="p1">
              <div class="title2">价值密度低</div>
              以视频为例，一小时的视频，在不间断的监控过程中，可能有用的数据仅仅只有一两秒。
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <img :src="require(`../../assets/${carrentBusiness}-b.png`)" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      carrentBusiness: "development",
      carrentId: 1,
      drawer: false,
    };
  },

  computed: {},

  watch: {},

  methods: {
    jumpTo(routerName) {
      this.$router.push(routerName);
    },
    handleCard() {
      // this.carrentBusiness = val;
      // this.drawer = true;
      this.$router.push("plm");
    },
    handleClose() {
      this.drawer = false;
    },
  },

  created() {},

  mounted() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  },

  beforeDestroy() {},
};
</script> 

<style lang="scss" scoped>
.business-wrapper {
  padding-top: 60px;
  width: 100%;
  min-width: 1200px;
  touch-action: none;
  overflow: hidden;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    padding: 0 35px 0 0px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 5px rgba($color: #1f2642, $alpha: 0.1);
    transition: all 0.5s;
    .logo {
      width: 200px;
      > img {
        width: 100%;
      }
    }
    .nav-cont {
      width: 400px;
      height: 60px;
      .nav {
        float: left;
        height: 60px;
        line-height: 60px;
        color: #707070;
        padding: 0 15px;
        cursor: pointer;
        font-size: 18px;
      }
      .nav:hover {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
      .navActive {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
    }
  }

  .business {
    width: 1200px;
    margin: auto;
    padding: 40px 0;
    .b-title {
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 20px;
    }
    .b-title2 {
      font-size: 20px;
    }
    .business-cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card {
        width: 360px;
        height: 435px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 25px 0;
        cursor: pointer;
        .cont {
          width: 100%;
          padding: 10px 40px;
          height: 300px;
          background: #f2f5ff;
          overflow: hidden;
          > img {
            width: 100%;
          }
        }
        .info {
          height: 120px;
          padding: 24px;
          text-align: left;
          .tit {
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 10px;
          }
          .text {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .card:hover {
        box-shadow: 0px 2px 10px 0px rgba(26, 23, 255, 0.3);
      }
    }
  }
  .tabs {
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 600px;
    display: flex;
    .card {
      width: 25%;
      background: chocolate;
      transition: all 0.5s;
      background-size: cover;
      overflow: hidden;
      .info {
        width: 100%;
        height: 100%;
        color: #fff;
        text-align: left;
        padding: 90px;
        transition: all 0.3s;
        .title1 {
          font-size: 24px;
          padding: 20px 0;
        }
        .title2 {
          font-size: 20px;
          line-height: 28px;
          float: right;
        }
        .title3 {
          font-size: 20px;
          padding: 60px 0 0px;
          opacity: 0;
          transition: all 0.3s;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #fff;
          opacity: 0;
          transition: all 0.3s;
        }
        .text {
          width: calc(50vw - 180px);
          padding: 20px 0;
          font: 16px;
          opacity: 0;
          transition: all 0.3s;
          line-height: 180%;
          p {
            margin: 0;
          }
        }
      }
    }
    .card-active {
      width: 50%;
      .info {
        background: rgba(33, 29, 29, 0.67);
        .title3 {
          opacity: 1;
        }
        .line {
          opacity: 1;
        }
        .text {
          opacity: 1;
        }
      }
    }
    .card:first-child {
      background: url("../../assets/a1.png") no-repeat;
    }
    .card:nth-child(2) {
      background: url("../../assets/a2.png") no-repeat;
    }
    .card:last-child {
      background: url("../../assets/a3.png") no-repeat;
    }
  }
  .drawer-class {
    .drawer-cont {
      width: 100%;
      display: flex;
      padding: 0 10%;
      > div {
        width: 50%;
      }
      .drawer-left {
        text-align: left;
        .title1 {
          font-size: 24px;
          padding-bottom: 20px;
          font-weight: bold;
        }
        .title2 {
          font-size: 20px;
          padding-bottom: 20px;
          font-weight: bold;
        }
        .p1 {
          font-size: 16px;
          padding-bottom: 20px;
          line-height: 150%;
          color: #3b3d41;
        }
        .chart-percent {
          font-size: 32px;
          text-align: center;
          padding: 10px 0 20px 0;
        }
        .chart-info {
          font-size: 16px;
          color: #93a4b3;
          text-align: center;
          padding-bottom: 40px;
        }
      }
      .drawer-right {
        padding-top: 5%;
        text-align: right;
        img {
          width: 100%;
          width: 60%;
        }
      }
    }
  }

  .footer {
    background: #26282e;
    color: #a4a5a8;
    position: relative;
    overflow: hidden;
    height: 77px;
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-top: -2px;
    }
    .line {
      width: calc(100% - 120px);
      height: 1px;
      background: #494c4f;
      margin: 0 auto;
    }
    .footer-cont {
      width: 1200px;
      margin: auto;
      .box1 {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
      }
      .box2 {
        padding: 15px 0;
        font-size: 12px;
        line-height: 16px;
        a {
          margin-left: 10px;
          color: #a4a5a8;
          text-decoration: none;
        }
      }
      background: #26282e;
    }
  }
}
</style>
<style lang="scss">
.drawer-class {
  .el-drawer__open .el-drawer.btt {
    height: 600px !important;
    border-radius: 60px 60px 0 0;
  }
  .el-drawer__close-btn {
    font-size: 30px;
  }
  .el-drawer__header {
  }
  .el-drawer__body {
    overflow: auto;
  }
}
</style>